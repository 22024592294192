import React from "react";
import Seo from "../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const KontaktPage = () => {
  return (
    <Layout>
      <Seo
        title="Kontakt"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <div
        className="d-none d-lg-block"
        style={{ position: "relative", zIndex: -1 }}
      >
        <Container className="g-0 position-absolute" fluid={true}>
          <Row className="g-0 justify-content-end">
            <Col xs={4} className="g-0">
              <StaticImage
                src="../images/blumentopf-cut-header.png"
                alt=""
                placeholder="tracedSVG"
                layout="fullWidth"
                sizes="33.3333333333vw"
                // formats={["auto", "webp", "avif"]}
                // sizes="(max-width: 575px) calc(50vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-md-5 py-5">
        <Row>
          <Col md={6} lg={4}>
            <h2 className="section-title">Praxis</h2>
            <p>
              Zollikerstrasse 19 <br />
              <b>Bitte bei Psylance klingeln</b>
              <br />
              8702 Zollikon
            </p>
          </Col>
          <Col md={6} lg={4}>
            <h2 className="section-title">Kontakt & Termine</h2>
            <p>
              Telefon:{" "}
              <a href="tel:+41 79 329 25 87" className="text-secondary">
                079 329 25 87
              </a>
              <br />
              <a href="mailto:praxis@prenosil.ch" className="text-secondary">
                praxis@prenosil.ch
              </a>
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>
            <p>
              Diese Parkplätze dürfen benützt werden <br />
              (mit Psylance angeschrieben):
            </p>
            <div className="my-4">
            <StaticImage
              src="../images/parkplaetze.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   243, 289, 321, 426, 456, 486, 536, 546, 578, 636, 642, 852, 912,
              //   1072, 1092, 1272,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw /12 * 10 - 24px), (max-width: 767px) 426px, (max-width: 991px) 456px, (max-width: 1199px) 536px, (max-width: 1399px) 546px, 636px"
            />
            </div>
            <p>
              <a
                href="https://goo.gl/maps/hDh4YJEEwYdKuupC7"
                target="_blank"
                rel="noreferrer"
                className="text-secondary"
              >
                zum Anfahrtsplan &gt;
              </a>
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xs={10} md={8} lg={7} xl={6}>
            <StaticImage
              src="../images/home-sun-line-plant.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   243, 289, 321, 426, 456, 486, 536, 546, 578, 636, 642, 852, 912,
              //   1072, 1092, 1272,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw /12 * 10 - 24px), (max-width: 767px) 426px, (max-width: 991px) 456px, (max-width: 1199px) 536px, (max-width: 1399px) 546px, 636px"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default KontaktPage;
